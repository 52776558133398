export default {
  en: {
    button: "Subscribe",
    stayUpToDate: "Stay up to date with the news",
  },
  uk: {
    button: "Підписатися",
    stayUpToDate: "Будь в курсі новин",
  },
};
