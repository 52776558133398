import { BASE_URL, Locale } from "@/constants";
import { PublicInformationRequest, PublicInformationRespose } from "@/interfaces";

export default {
  async getPublicInformationCSRF(): Promise<PublicInformationRespose> {
    const response = await fetch(`${BASE_URL}forms/public_information_request`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return response.json();
  },
  async postPublicInformationRequest(
    locale: Locale,
    requestBody: PublicInformationRequest,
  ): Promise<void> {
    const formData = new FormData();

    formData.append("legal_entity", encodeURIComponent(requestBody.legalEntity));
    formData.append("name", encodeURIComponent(requestBody.name));
    formData.append("phone", encodeURIComponent(requestBody.phone));
    formData.append("email", encodeURIComponent(requestBody.email));
    formData.append("postal_address", encodeURIComponent(requestBody.postalAddress));
    formData.append("request", encodeURIComponent(requestBody.request));
    formData.append("respond_by", encodeURIComponent(requestBody.respondBy));
    formData.append("csrf_token", encodeURIComponent(requestBody.csrfToken));

    const response = await fetch(`${BASE_URL}forms/public_information_request?language=${locale}`, {
      method: "POST",
      body: formData,
    });
    if (response.status !== 200) {
      throw response.status;
    }
  },
};
