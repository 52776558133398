import { BASE_URL, Locale } from "@/constants";
import { ReportCorruptionRequest, ReportCorruptionResponse } from "@/interfaces";

export default {
  async getReportCorruptionCSRF(): Promise<ReportCorruptionResponse> {
    const response = await fetch(`${BASE_URL}forms/prevent_corruption`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return response.json();
  },
  async postReportCorruptionRequest(
    locale: Locale,
    requestBody: ReportCorruptionRequest,
  ): Promise<void> {
    const formdata = new FormData();

    formdata.append("name", encodeURIComponent(requestBody.name));
    formdata.append("place_of_work", encodeURIComponent(requestBody.placeOfWork));
    formdata.append("position", encodeURIComponent(requestBody.position));
    formdata.append("message", encodeURIComponent(requestBody.message));
    formdata.append("attachment", encodeURIComponent(requestBody.attachment));
    formdata.append("csrf_token", encodeURIComponent(requestBody.csrfToken));

    const response = await fetch(`${BASE_URL}forms/prevent_corruption?language=${locale}`, {
      method: "POST",
      body: formdata,
      redirect: "follow",
    });
    if (response.status !== 200) {
      throw response.status;
    }
  },
};
